<template>
  <div class="group-providers">
    <atomic-icon
      :id="getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'cardsGroup.providers.icon')"
    />

    <h2 class="title">
      {{ getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'cardsGroup.providers.label') }}
    </h2>

    <button-base v-if="props.showAllBtn" class="btn-show-all" type="ghost" size="sm" url="/providers">
      {{ getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'cardsGroup.moreButton') }}
      {{ providersIdentity.length }}
    </button-base>

    <button-arrows
      v-if="showArrowButtons"
      :prevDisabled="prevDisabled"
      :nextDisabled="nextDisabled"
      @click-action="clickAction"
    />

    <div v-if="providersList.length" ref="scrollContainer" class="items" @scroll="scrollHandler">
      <card-providers v-for="provider in providersList" :key="provider.id" :providerData="provider" />
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { IGameProvider } from '@skeleton/core/types';

  const props = defineProps<{
    showAllBtn?: boolean;
    showArrows?: boolean;
    exclude?: string[];
  }>();

  const providersIdentity = ['betsy', 'pragmatic-play', 'pg-soft', 'spribe', 'evolution-gaming', 'nolimit-city'];
  const { globalComponentsContent, defaultLocaleGlobalComponentsContent } = useGlobalStore();
  const { getContent } = useProjectMethods();
  const { getProviderList } = useGamesStore();
  const { data: gameProviders } = await useLazyAsyncData(() => getProviderList(), { server: false });

  const providersList = computed(() => {
    const filtered = providersIdentity.filter(item => !props.exclude?.includes(item));
    return (props.exclude ? filtered : providersIdentity).reduce(
      (providersArr: IGameProvider[], currentProviderIdentity) => {
        const providerData = gameProviders.value?.find(provider => provider.identity === currentProviderIdentity);
        if (providerData) return [...providersArr, providerData];
        return providersArr;
      },
      []
    );
  });

  const scrollContainer = ref();
  const prevDisabled = ref<boolean>(true);
  const nextDisabled = ref<boolean>(false);
  const showArrowButtons = ref<boolean>(props.showArrows);

  const scrollHandler = (): void => {
    if (!scrollContainer.value) return;
    const { scrollLeft, offsetWidth, scrollWidth } = scrollContainer.value;
    prevDisabled.value = scrollLeft === 0;
    nextDisabled.value = scrollWidth < scrollLeft + offsetWidth + 20 && scrollWidth > scrollLeft + offsetWidth - 20;
  };

  watch(gameProviders, () => {
    scrollHandler();
  });

  const clickAction = (direction: string): void => {
    const { offsetWidth, scrollWidth, scrollLeft } = scrollContainer.value;
    const widthToEnd = scrollWidth - (scrollLeft + offsetWidth);
    const scrollLeftValue = widthToEnd < offsetWidth ? widthToEnd : offsetWidth;
    const scrollRightValue = scrollLeft < offsetWidth ? scrollLeft : offsetWidth;
    scrollContainer.value.scrollBy({
      left: direction === 'next' ? scrollLeftValue : -scrollRightValue,
      behavior: 'smooth',
    });
  };

  onMounted(() => {
    if (props.showArrows) {
      scrollHandler();
      showArrowButtons.value = props.showArrows && (!prevDisabled.value || !nextDisabled.value);
    }
  });
</script>

<style src="@skeleton/assets/styles/components/group/providers.scss" lang="scss" />
